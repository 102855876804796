<template>
  <div>
    <h2>用户表</h2>
    <table border="1">
      <thead>
        <tr>
          <th>ID</th>
          <th>姓名</th>
          <th>邮件</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.id }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>
            <button @click="editUser(user)">修改</button>
            <button @click="deleteUser(user.id)">删除</button>
          </td>
        </tr>
      </tbody>
    </table>
    <h3>增加用户</h3>
    <form @submit.prevent="addUser">
      <input v-model="newUser.name" placeholder="姓名" />
      <input v-model="newUser.email" placeholder="邮件" />
      <button type="submit">增加</button>
    </form>
    <h3 v-if="editingUser">修改用户</h3>
    <form v-if="editingUser" @submit.prevent="updateUser">
      <input v-model="editingUser.name" placeholder="姓名" />
      <input v-model="editingUser.email" placeholder="邮件" />
      <button type="submit">更新</button>
      <button @click="cancelEdit">取消</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [
        { id: 1, name: '张三', email: 'zhangsan@example.com' },
        { id: 2, name: '李四', email: 'lisi@example.com' },
        { id: 3, name: '王五', email: 'wangwu@example.com' }
      ],
      newUser: {
        name: '',
        email: ''
      },
      editingUser: null
    };
  },
  methods: {
    addUser() {
      const id = this.users.length + 1;
      this.users.push({ id, ...this.newUser });
      this.newUser.name = '';
      this.newUser.email = '';
    },
    deleteUser(id) {
      this.users = this.users.filter(user => user.id !== id);
    },
    editUser(user) {
      this.editingUser = { ...user };
    },
    updateUser() {
      const index = this.users.findIndex(u => u.id === this.editingUser.id);
      if (index !== -1) {
        this.users[index] = { ...this.editingUser };
      }
      this.editingUser = null;
    },
    cancelEdit() {
      this.editingUser = null;
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
}
</style>